import { RiArrowLeftRightLine, RiFileList2Line, RiFolder6Line, RiUser3Line } from "react-icons/ri";
import type { CategoryNavigationItem, NavigationItem } from "~types/app/navigation.types";

export const disabledCategories = ["example-category-slug"];

export const categoriesWithRanges = ["systemes-de-fixation"];

export const categoriesWithBrandsListingPage = ["systemes-de-fixation"];

export const categoriesWithoutBrandsFilters = [
  "kits-solaires",
  "ombrieres-et-carports",
  "cables-et-connectiques",
  "coffrets-electriques",
];

export const categoriesNavGroups = [
  {
    label: "Kits",
    childrenSlugs: ["kits-solaires", "kits-plug-and-play", "ombrieres-et-carports"],
  },
  {
    label: "Matériel électrique",
    childrenSlugs: ["coffrets-electriques", "cables-et-connectiques", "e-mobilite"],
  },
];

// kits solaires
// kits plug and play
// ombrières
// modules
// onduleurs
// batteries
// coffrets.

export const categoriesWithPromoButton = [
  "kits-solaires",
  "kits-plug-and-play",
  "ombrieres-et-carports",
  "modules-photovoltaiques",
  "onduleurs-solaires",
  "batteries-solaires",
  "coffrets-electriques",
];

export const staticCategoryNavItems: CategoryNavigationItem[] = [
  {
    slug: "kits-solaires",
    types: [
      {
        label: "Monophasé",
        to: "/kits-solaires?filtres=monophase",
      },
      {
        label: "Triphasé",
        to: "/kits-solaires?filtres=triphase",
      },
      {
        label: "Puissance < 3kWc",
        to: "/kits-solaires?filtres=puissance--0~3000",
      },
      {
        label: "Puissance entre 3 et 6kWc",
        to: "/kits-solaires?filtres=puissance--3000~6000",
      },
      {
        label: "Puissance > 6kWc",
        to: "/kits-solaires?filtres=puissance--6000~9000",
      },
    ],
    topSearches: [
      {
        label: "Kit solaire monophasé",
        to: "/kits-solaires?filtres=monophase",
      },
      {
        label: "Kit solaire triphasé",
        to: "/kits-solaires?filtres=triphase",
      },
      {
        label: "Kit solaire bifacial",
        to: "/kits-solaires?filtres=bifacial",
      },
    ],
  },
  {
    slug: "kits-plug-and-play",
    types: [],
    topSearches: [],
  },
  {
    slug: "ombrieres-et-carports",
    types: [],
    topSearches: [],
  },
  {
    slug: "modules-photovoltaiques",
    types: [
      {
        label: "Bas carbone CRE4",
        to: "/modules-photovoltaiques?filtres=certification-carbone-cre4",
      },
      {
        label: "Bas carbone PPE2",
        to: "/modules-photovoltaiques?filtres=certification-carbone-ppe2",
      },
      {
        label: "Résidentiel",
        to: "/modules-photovoltaiques?filtres=caracteristique-57",
      },
      {
        label: "Grand format",
        to: "/modules-photovoltaiques?filtres=longueur--2200~3000",
      },
      {
        label: "N-Type",
        to: "/modules-photovoltaiques?filtres=n-type",
      },
      {
        label: "Back Contact",
        to: "/modules-photovoltaiques?filtres=back-contact",
      },
      {
        label: "Bifacial",
        to: "/modules-photovoltaiques?filtres=bifacial",
      },
      {
        label: "Cadre Noir",
        to: "/modules-photovoltaiques?filtres=cadre-noir",
      },
      {
        label: "Cadre Silver",
        to: "/modules-photovoltaiques?filtres=cadre-silver",
      },
      {
        label: "Full Black",
        to: "/modules-photovoltaiques?filtres=full-black",
      },
      {
        label: "Transparent",
        to: "/modules-photovoltaiques?filtres=transparent",
      },
    ],
    topSearches: [],
  },
  {
    slug: "onduleurs-solaires",
    types: [
      {
        label: "Monophasé",
        to: "/onduleurs-solaires?filtres=monophase",
      },
      {
        label: "Triphasé",
        to: "/onduleurs-solaires?filtres=triphase",
      },
      {
        label: "Micro Onduleurs / Optimiseurs",
        to: "/onduleurs-solaires?filtres=micro-onduleurs-optimiseurs",
      },
      {
        label: "Hybride",
        to: "/onduleurs-solaires?filtres=hybride",
      },
      {
        label: "Monitoring",
        to: "/onduleurs-solaires?filtres=monitoring",
      },
    ],
    topSearches: [],
  },
  {
    slug: "systemes-de-fixation",
    types: [
      {
        label: "Toit incliné",
        to: "/systemes-de-fixation?filtres=toit-incline",
      },
      {
        label: "Toit terrasse",
        to: "/systemes-de-fixation?filtres=toit-terrasse",
      },
      {
        label: "Sol",
        to: "/systemes-de-fixation?filtres=toit-plat",
      },
      {
        label: "Façade",
        to: "/systemes-de-fixation?filtres=ombriere",
      },
      {
        label: "Accessoires",
        to: "/systemes-de-fixation?filtres=carport",
      },
    ],
    supports: [
      {
        label: "Ardoise",
        to: "/systemes-de-fixation?filtres=ardoise",
      },
      {
        label: "Bac Acier",
        to: "/systemes-de-fixation?filtres=bacacier",
      },
      {
        label: "Fibrociment",
        to: "/systemes-de-fixation?filtres=fibrociment",
      },
      {
        label: "Tôle Ondulée",
        to: "/systemes-de-fixation?filtres=tole-ondulee",
      },
      {
        label: "Tuiles Plates",
        to: "/systemes-de-fixation?filtres=tuiles-plates",
      },
      {
        label: "Tuiles Canal",
        to: "/systemes-de-fixation?filtres=tuiles-canal",
      },
      {
        label: "Tuiles Mécaniques",
        to: "/systemes-de-fixation?filtres=tuiles-mecaniques",
      },
      {
        label: "Membrane Bitume",
        to: "/systemes-de-fixation?filtres=membrane-bitume",
      },
      {
        label: "Membrane EPDM",
        to: "/systemes-de-fixation?filtres=membrane-epdm",
      },
      {
        label: "Membrane PVC",
        to: "/systemes-de-fixation?filtres=membrane-pvc",
      },
      {
        label: "Membrane TPO",
        to: "/systemes-de-fixation?filtres=membrane-tpo",
      },
      {
        label: "Ombrières",
        to: "/systemes-de-fixation?filtres=ombriere",
      },
      {
        label: "Systèmes Lestés",
        to: "/systemes-de-fixation?filtres=systeme-leste",
      },
    ],
    topSearches: [],
  },
  {
    slug: "batteries-solaires",
    types: [],
    topSearches: [],
  },
  {
    slug: "cables-et-connectiques",
    types: [
      {
        label: "Câbles AC",
        to: "/cables-et-connectiques?filtres=cable-ac",
      },
      {
        label: "Câbles 4mm²",
        to: "/cables-et-connectiques?filtres=cable-4mm2",
      },
      {
        label: "Câbles 6mm²",
        to: "/cables-et-connectiques?filtres=cable-6mm2",
      },
      {
        label: "Connecteurs et accessoires",
        to: "/cables-et-connectiques?filtres=connecteurs-et-accessoires",
      },
      {
        label: "Mise à la terre",
        to: "/cables-et-connectiques?filtres=mise-a-la-terre",
      },
      {
        label: "Outils",
        to: "/cables-et-connectiques?filtres=outils",
      },
    ],
    topSearches: [],
  },
  {
    slug: "coffrets-electriques",
    types: [
      {
        label: "Monophasé",
        to: "/coffrets-electriques?filtres=monophase",
      },
      {
        label: "Triphasé",
        to: "/coffrets-electriques?filtres=triphase",
      },
      {
        label: "Coffrets AC",
        to: "/coffrets-electriques?filtres=coffret-ac",
      },
      {
        label: "Coffrets DC",
        to: "/coffrets-electriques?filtres=coffret-dc",
      },
      {
        label: "Coffrets AC/DC",
        to: "/coffrets-electriques?filtres=coffret-ac-dc",
      },
      {
        label: "Accessoires",
        to: "/coffrets-electriques?filtres=accessoires",
      },
    ],
    topSearches: [],
  },
  {
    slug: "e-mobilite",
    types: [
      {
        label: "Monophasé",
        to: "/e-mobilite?filtres=monophase",
      },
      {
        label: "Triphasé",
        to: "/e-mobilite?filtres=triphase",
      },
      {
        label: "Accessoires",
        to: "/e-mobilite?filtres=accessoires",
      },
    ],
    topSearches: [],
  },
];

export const headerSecondaryNavItems: NavigationItem[] = [
  {
    label: "Outils et services",
    to: "/outils-et-services",
  },
  {
    label: "Nos partenaires",
    to: "/nos-partenaires",
  },
  {
    label: "Ressources",
    children: [
      {
        label: "Glossaire",
        to: "/glossaire",
      },
      {
        label: "FAQ",
        to: "/faq/connexion",
      },
    ],
  },
  {
    label: "A propos",
    to: "/a-propos/qui-sommes-nous",
  },
];

export const accountNavItems: NavigationItem[] = [
  {
    label: "Mon profil",
    to: "/account/profile",
    icon: RiUser3Line,
    children: [
      {
        label: "Mes informations",
        to: "/account/profile/informations",
      },
      {
        label: "Mes adresses",
        to: "/account/profile/addresses",
      },
    ],
  },
  {
    label: "Mes affaires et devis",
    to: "/account/carts",
    icon: RiFileList2Line,
  },
  {
    label: "Mes documents",
    to: "/account/documents",
    icon: RiFolder6Line,
    children: [
      {
        label: "Mes commandes",
        to: "/account/documents/orders",
      },
      {
        label: "Mes bons de livraison",
        to: "/account/documents/delivery-notes",
      },
      {
        label: "Mes factures",
        to: "/account/documents/invoices",
      },
      {
        label: "Mes avoirs",
        to: "/account/documents/credits",
      },
    ],
  },
  {
    label: "Mon comparateur",
    to: "/account/comparator",
    icon: RiArrowLeftRightLine,
  },
];

export const administrationNavItems: NavigationItem[] = [
  {
    label: "Contenu éditorial",
    to: "/admin/pages",
    children: [
      {
        label: "Pages",
        to: "/admin/pages",
      },
    ],
  },
  {
    label: "Contenu commercial",
    to: "/admin/slider",
    children: [
      {
        label: "Slider page d'accueil",
        to: "/admin/slider",
      },
    ],
  },
  {
    label: "Outils et services",
    to: "/admin/tools-and-services/thumbnails",
    children: [
      {
        label: `Vignettes`,
        to: "/admin/tools-and-services/thumbnails",
      },
      {
        label: `Page "Assurance"`,
        to: "/admin/tools-and-services/insurance",
      },
    ],
  },
];

export const categoriesDisabledInSiteMap = ["confidentialite"];
